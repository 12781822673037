<template>
    <main class="main-page"  id="">
        <template v-if="pageReady">
            <template v-if="showHeader">
                <section class="page-section mb-3" >
                    <div class="container">
                        <div class="grid align-items-center">
                            <div  v-if="!isSubPage"  class="col-fixed " >
                                <Button @click="$router.go(-1)" label=""  class="p-button p-button-text " icon="pi pi-arrow-left"  />
                            </div>
                            <div  class="col " >
                                <div class=" text-2xl text-primary font-bold" >
                                    Edit Form P2h
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </template>
            <section class="page-section " >
                <div class="container">
                    <div class="grid ">
                        <div  class="md:col-9 sm:col-12 comp-grid" >
                            <div >
                                <form ref="observer"  tag="form" @submit.prevent="submitForm()" :class="{ 'card ': !isSubPage }" class=" ">
                                    <!--[form-content-start]-->
                                    <div class="grid">
                                        <Fieldset legend="Profil" :toggleable="true">
                                            <div class="grid">
                                                <div class="col-12">
                                                    <div class="formgrid grid">
                                                        <div class="col-12 md:col-3">
                                                            Nama 
                                                        </div>
                                                        <div class="col-12 md:col-9">
                                                            <InputText  ref="ctrlnama" v-model.trim="formData.nama"  label="Nama" type="text" placeholder="Enter Nama"    readonly  
                                                            class=" w-full" :class="getErrorClass('nama')">
                                                            </InputText>
                                                            <small v-if="isFieldValid('nama')" class="p-error">{{ getFieldError('nama') }}</small> 
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-12">
                                                    <div class="formgrid grid">
                                                        <div class="col-12 md:col-3">
                                                            Employee Id 
                                                        </div>
                                                        <div class="col-12 md:col-9">
                                                            <InputText  ref="ctrlemployee_id" v-model.trim="formData.employee_id"  label="Employee Id" type="text" placeholder="Enter Employee Id"    readonly  
                                                            class=" w-full" :class="getErrorClass('employee_id')">
                                                            </InputText>
                                                            <small v-if="isFieldValid('employee_id')" class="p-error">{{ getFieldError('employee_id') }}</small> 
                                                        </div>
                                                    </div>
                                                </div>
                                            </div></fieldset>
                                            <Fieldset legend="Form P2H" :toggleable="true">
                                                <div class="grid">
                                                    <div class="col-12">
                                                        <div class="formgrid grid">
                                                            <div class="col-12 md:col-3">
                                                                Apakah kondisi kendaraan anda baik hari ini? 
                                                            </div>
                                                            <div class="col-12 md:col-9">
                                                                <div class="field-radiobutton" v-for="option of app.menus.q1Items" :key="option.value">
                                                                    <RadioButton  :class="getErrorClass('q1')" :id="option.value" name="ctrlq1" :value="option.value" v-model="formData.q1" />
                                                                    <label :for="option.value">{{option.label}} </label>
                                                                </div>
                                                                <small v-if="isFieldValid('q1')" class="p-error">{{ getFieldError('q1') }}</small> 
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-12">
                                                        <div class="formgrid grid">
                                                            <div class="col-12 md:col-3">
                                                                Apakah mesin kendaraan prima? 
                                                            </div>
                                                            <div class="col-12 md:col-9">
                                                                <div class="field-radiobutton" v-for="option of app.menus.q1Items" :key="option.value">
                                                                    <RadioButton  :class="getErrorClass('q2')" :id="option.value" name="ctrlq2" :value="option.value" v-model="formData.q2" />
                                                                    <label :for="option.value">{{option.label}} </label>
                                                                </div>
                                                                <small v-if="isFieldValid('q2')" class="p-error">{{ getFieldError('q2') }}</small> 
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-12">
                                                        <div class="formgrid grid">
                                                            <div class="col-12 md:col-3">
                                                                Apakah kelengkapan kendaran dalam kondisi baik? 
                                                            </div>
                                                            <div class="col-12 md:col-9">
                                                                <div class="field-radiobutton" v-for="option of app.menus.q1Items" :key="option.value">
                                                                    <RadioButton  :class="getErrorClass('q3')" :id="option.value" name="ctrlq3" :value="option.value" v-model="formData.q3" />
                                                                    <label :for="option.value">{{option.label}} </label>
                                                                </div>
                                                                <small v-if="isFieldValid('q3')" class="p-error">{{ getFieldError('q3') }}</small> 
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <input name="ctrlapproval_1"  ref="ctrlapproval_1" v-model="formData.approval_1" type="hidden" />
                                                    <input name="ctrlapproval_2"  ref="ctrlapproval_2" v-model="formData.approval_2" type="hidden" />
                                                    <input name="ctrlapproval_3"  ref="ctrlapproval_3" v-model="formData.approval_3" type="hidden" />
                                                </div></fieldset>
                                            </div>
                                            <!--[form-content-end]-->
                                            <div v-if="showSubmitButton" class="text-center my-3">
                                                <Button type="submit" label="Update" icon="pi pi-send" :loading="saving" />
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </template>
                <template v-if="loading">
                    <div style="min-height:200px" class="flex gap-3 justify-content-center align-items-center p-3">
                        <div><ProgressSpinner style="width:50px;height:50px" /> </div>
                        <div class="text-500">Loading... </div>
                    </div>
                </template>
            </main>
</template>
<script setup>
	import {  computed,  reactive, toRefs, onMounted } from 'vue';
	import { required } from 'src/services/validators';
	import { useApp } from 'src/composables/app.js';
	import { useAuth } from 'src/composables/auth';
	import { useEditPage } from 'src/composables/editpage.js';
	import { usePageStore } from 'src/store/page';
	const props = defineProps({
		id: [String, Number],
		pageStoreKey: {
			type: String,
			default: 'FORM_P2H',
		},
		pageName: {
			type: String,
			default: 'form_p2h',
		},
		routeName: {
			type: String,
			default: 'form_p2hedit',
		},
		pagePath: {
			type : String,
			default : 'form_p2h/edit',
		},
		apiPath: {
			type: String,
			default: 'form_p2h/edit',
		},
		submitButtonLabel: {
			type: String,
			default: "Update",
		},
		formValidationError: {
			type: String,
			default: "Form is invalid",
		},
		formValidationMsg: {
			type: String,
			default: "Please complete the form",
		},
		msgTitle: {
			type: String,
			default: "Update Record",
		},
		msgBeforeSave: {
			type: String,
			default: "",
		},
		msgAfterSave: {
			type: String,
			default: "Data berhasil diupdate",
		},
		showHeader: {
			type: Boolean,
			default: true,
		},
		showSubmitButton: {
			type: Boolean,
			default: true,
		},
		redirect: {
			type : Boolean,
			default : true,
		},
		isSubPage: {
			type : Boolean,
			default : false,
		},
	});
	
	const store = usePageStore(props.pageStoreKey);
	const app = useApp();
	const auth = useAuth();
	
	const formDefaultValues = Object.assign({
		nama: auth.user.nama, 
		employee_id: auth.userName, 
		q1: "", 
		q2: "", 
		q3: "", 
		approval_1: "Approved", 
		approval_2: "", 
		approval_3: "", 
	}, props.pageData);
	
	const formData = reactive({ ...formDefaultValues });
	
	function afterSubmit(response) {
		app.flashMsg(props.msgTitle, props.msgAfterSave);
		if(app.isDialogOpen()){
			app.closeDialogs(); // if page is open as dialog, close dialog
		}
		else if(props.redirect) {
			app.navigateTo(`/form_p2h`);
		}
	}
	
	// form validation rules
	const rules = computed(() => {
		return {
			nama: {  },
			employee_id: {  },
			q1: {  },
			q2: {  },
			q3: {  },
			approval_1: {  },
			approval_2: {  },
			approval_3: {  }
		}
	});
	
	const page = useEditPage({store, props, formData, rules, afterSubmit });
	
	const {  currentRecord: editRecord } = toRefs(store.state);
	const {  pageReady, saving, loading, } = toRefs(page.state);
	
	const { apiUrl } = page.computedProps;
	
	const { load, submitForm, getErrorClass, getFieldError, isFieldValid,  } = page.methods;
	
	onMounted(()=>{
		const pageTitle = "Edit Form P2h";
		app.setPageTitle(props.routeName, pageTitle); // set browser page title
	});
</script>
<style scoped>
</style>
